import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import EurekaComponents from 'eureka';

const { Spinner } = EurekaComponents.components;

export class MashupLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    settings: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div
          style={{
            minHeight: '100vh !important',
            height: '100vh !important',
          }}
        >
          <div className="fd-app" style={{ height: '100vh' }}>
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MashupLayout);
