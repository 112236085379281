/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import '@ui5/webcomponents-icons/dist/Assets-static';
import { MessageStrip,MessageStripDesign,ThemeProvider } from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import eureka from 'eureka';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { listenToEventBus, mergeSettings, renderRouteConfigV3 } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { constructPermissions, getURLParam, TestingLocales } from './common/Utils';
import { MicroFrontend } from './features/common';
import { getRandom } from './features/common/Utils';
import '@ui5/webcomponents-fiori/dist/illustrations/tnt/SessionExpired.js';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-icons/dist/Assets.js';
import { setLanguage as setLibLanguage } from '@ui5/webcomponents-base/dist/config/Language.js';
import { defaultTimezoneKey, defaultTimezoneRegionId } from './features/constants/timezone.constants';
import {
  DEFAULT_THEME,
  PX_API_OBJECT,
  PX_UNIT_ID,
  PX_URL,
} from './features/constants/pxFeedback.constants';
import {
  FALLBACK_LANG,
  FALLBACK_THEME,
  baseUrl,
  sourceUrl,
} from './features/constants/builtInSupport.constants';
import {
  Dialog,
  IllustratedMessage,
  IllustrationMessageType,
  Button,
  Title,
  FlexBox,
  ToolbarSpacer,
  MessageBox,
} from '@ui5/webcomponents-react';
import PageNotAuthorized from './features/common/PageNotAuthorized';
import { PxApi } from '@sap-px/pxapi';
import { getCurrentAppContextData } from './features/common/Header';
import FeedbackDialog from './features/common/FeedbackDialog';
const { MessageToast } = eureka.controls;
const { initI18n, setLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig, getConfig, setCsrfToken, updateFeatureToggle, getFeatureToggle } =
  eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;
const { eventBus } = eurekaMgrs;
const { getDefaultThemeId, getDefaultThemeList, setThemeId, setThemeList, setDefaultThemeId } =
  eurekaMgrs.AppearanceManager;
let config = null;
let lng = 'en';
let themeId = getDefaultThemeId();
const testingLng = getURLParam(window.location.search, 'sap-language');
const testingThemeId = getURLParam(window.location.search, 'sap-ui-theme');
let _pxApi = null;
let resolveSurveyInvitation;

const { getThemeId } = eurekaMgrs.AppearanceManager;

export const loader = () => {
  return <div>Loading...</div>;
};

// eslint-disable-next-line arrow-body-style
export const renderError = (msg) => {
  return (
    <MessageStrip
      style={{ marginTop: '10px', marginRight: '10px' }}
      design={MessageStripDesign.Negative}
      icon="error"
      noIcon={false}
      noCloseButton
      onClose={() => {}}
    >
      {msg}
    </MessageStrip>
  );
};

export const onClickYesOfFeedbackDialog = (currentContextData, feedbackDialogRef) => {
  const resultData = {
    appContextData: currentContextData,
    surveyUser: true,
  };
  resolveSurveyInvitation(resultData);
  feedbackDialogRef?.current?.close();
};

export const onClickNotnowOfFeedbackDialog = (currentContextData, feedbackDialogRef) => {
  const resultData = {
    appContextData: currentContextData,
    surveyUser: false,
  };
  resolveSurveyInvitation(resultData);
  feedbackDialogRef?.current?.close();
};

export const renderFeedbackDialog = ({ feedbackDialogRef }) => {
  const { pxAPIInstance } = getConfig(PX_API_OBJECT) || {};
  const currentContextData = getCurrentAppContextData(pxAPIInstance?.prevThemeId);
  return (
    <FeedbackDialog
      currentContextData={currentContextData}
      ref={feedbackDialogRef}
      onClickYesHandler={onClickYesOfFeedbackDialog}
      onClickNotnowHandler={onClickNotnowOfFeedbackDialog}
    />
  );
};

export const renderTimeoutMsg = () => {
  return (
    <Dialog
      open
      headerText="Session Info"
      footer={
        <>
          <ToolbarSpacer />
          <Button
            style={{ margin: '4px' }}
            onClick={(e) => {
              window.location.href = '/login?application=prp';
            }}
          >
            {'Sign In'}
          </Button>
        </>
      }
    >
      <IllustratedMessage
        titleText="Your Session has expired."
        subtitleText="Please sign in again."
        name={IllustrationMessageType.TntSessionExpired}
      />
    </Dialog>
  );
};
export const MicroFrontendWrapper = ({ history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({ mfdRouters, history, settings, user }) => {
  const routes = [];
  mfdRouters?.forEach((app) => {
    app.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

export const checkSessionTimeout = ({ fetchUserDetails, setState }) => {
  fetchUserDetails().then(
    (result) => {
      setTimeout(() => {
        checkSessionTimeout({ fetchUserDetails, setState });
      }, 3000000);
    },
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 504) {
        setState((prevState) => {
          return {
            ...prevState,
            timedOut: true,
          };
        });
      } else {
        setTimeout(() => {
          checkSessionTimeout({ fetchUserDetails, setState });
        }, 3000000);
      }
    },
  );
};

export const createComponentVersionRouterMap = (host, component) => {
  const initialPxAPIObject = getConfig(PX_API_OBJECT) || {};
  const updatedComponentVersionMap = {
    ...initialPxAPIObject?.componentVersionMap,
    [component.name]: {
      commitId: host.split('/').pop(),
      routers: component.config.routers,
    },
  };
  addConfig(PX_API_OBJECT, {
    ...initialPxAPIObject,
    componentVersionMap: updatedComponentVersionMap,
  });
};

const onFetchConfigSuccess = ({ manifest, state, setState, setAuthorizedMicroFrontends }) => {
  const { cdn } = manifest.config;
  const shell = manifest['shell-ui'];
  let shellHost = '';
  const authorizedMicroFrontends = [];
  if (shell) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      shellHost = `${cdn}:${shell.port}`;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (shell.location) {
        shellHost = shell.location;
      } else {
        shellHost = `${cdn}/static/${shell.name}/${shell.commit}`;
      }
    }
  } else {
    console.error('Shell config is missed in config.json, please check');
  }
  const i18nNamespaces = { shell: shellHost };

  manifest.components.forEach((comp) => {
    let host = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      host = `${cdn}:${comp.port}`;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (comp.location) {
        host = comp.location;
      } else {
        host = `${cdn}/static/${comp.name}/${comp.commit}`;
      }
    }

    if (comp.config.app === 'ome-configuration') {
      i18nNamespaces.configuration = host;
    }

    // add i18n hosts
    i18nNamespaces[comp.config.app] = host;

    authorizedMicroFrontends.push({
      name: comp.config.app,
      host,
      routers: comp.config.routers,
    });
    createComponentVersionRouterMap(host, comp);
  });

  config = manifest;
  setState((prevState) => {
    return {
      ...prevState,
      config: config,
    };
  });

  setAuthorizedMicroFrontends(authorizedMicroFrontends);

  // add app config into config manager
  addConfig('appConfig', config);

  initI18n(i18nNamespaces, {
    debug: false,
    lowerCaseLng: false,
    fallbackLng: 'en',
    fallbackNS: 'shell',
    whitelist: false,
    lng, // en-US en-US-sappsd
    load: 'currentOnly',
    defaultNS: 'shell',
    ns: 'shell',
    preload: [lng], // en-US en-US-sappsd
    react: {
      useSuspense: false,
      wait: false,
    },
  });

  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setState((prevState) => {
      return {
        ...prevState,
        initializing: false,
      };
    });
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth, user }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

const onFetchCsrfFailed = ({ error, user, state, setState }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState((prevState) => {
    return {
      ...prevState,
      settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
      user: user.current,
      fetchConfigError: null,
    };
  });
};

export const onFetchSettingsFinally = ({
  rawSettings,
  state,
  setState,
  surveyInvitationDialogCallback,
}) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  const { tenantId, themeId } = getConfig('CurrentUser') || {
    tenantId: 'test',
    themeId: DEFAULT_THEME,
  };
  const isProdCluster = getConfig('IsProdCluster') || false;
  const envDetails = isProdCluster ? 'prod' : 'dev';
  const _configIdentifier = {
    unitId: PX_UNIT_ID,
    environment: envDetails,
    configUrl: PX_URL,
  };
  if (_pxApi === undefined || _pxApi === null) {
    _pxApi = new PxApi();
  }
  const initialPxAPIObject = getConfig(PX_API_OBJECT);
    _pxApi
      .initialize(
        { tenantId: tenantId.toString(), tenantRole: envDetails },
        _configIdentifier,
        undefined,
        surveyInvitationDialogCallback,
      )
      .then((response) => {
        if (response) {
          console.log('Successfully initialized PX-API');
          _pxApi.currentThemeId = themeId;
          _pxApi.prevThemeId = themeId;
          const pxAPI = {
            ...initialPxAPIObject,
            pxAPIInstance: _pxApi,
            isPxApiInitialized: response,
          };
          addConfig(PX_API_OBJECT, pxAPI);
        } else {
          console.log('Failed to initialize PX-API');
        }
      });
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }

  if (testingThemeId) {
    themeId = testingThemeId;
  }

  //set theme
  setThemeId(themeId);
  setTheme(themeId);

  // set new language
  setLanguage(lng);
  configManagerSetLanguage(lng);
  setLibLanguage(lng);
  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setState((prevState) => {
    return {
      ...prevState,
      initializing: false,
      triggerTimeoutCheck: true,
    };
  });
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data !== undefined && results[index]?.data !== null
    ? results[index]?.data
    : defValue;
};

const getHost = (app, cdn) => {
  let host = '';
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    host = `${cdn}:${app.port}`;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (app.location) {
      host = app.location;
    } else {
      host = `${cdn}/static/${app.name}/${app.commit}`;
    }
  }
  return host;
};

export const addMfesByRoles = (
  cdn,
  appComponents,
  currentUserPermissions,
  setAuthorizedMicroFrontends,
  setAllMicroFrontends,
) => {
  const authorizedMfe = [];
  const allMfe = [];
  let apps = appComponents ? appComponents.filter((app) => !app.config?.sidenav) : [];
  let sideNavApps = appComponents ? appComponents.filter((app) => app.config?.sidenav) : [];

  sideNavApps.map((appComp) => {
    let appName = appComp.config.app;
    let appRouters = [...appComp.config.routers];
    appComp.config?.sidenav?.forEach((nav) => {
      if (nav.items && nav.items.length > 0) {
        nav.items.forEach((subItem) => {
          if (
            !(
              (subItem.hasOwnProperty('skipPermission') && subItem.skipPermission === 'true') ||
              (subItem.conjunction === 'OR' &&
                subItem.permissions?.some((permission) =>
                  currentUserPermissions.roles.includes(permission),
                )) ||
              (subItem.conjunction === 'AND' &&
                subItem.permissions.every((permission) =>
                  currentUserPermissions.roles.includes(permission),
                ))
            )
          ) {
            appRouters = appRouters.filter((r) => !r.startsWith(subItem.router));
            const i = apps.findIndex((a) => a.config?.routers.includes(subItem.router));
            if (i >= 0) {
              apps.splice(i, 1);
            }
          } else if (apps.find((a) => a.config?.routers.includes(subItem.router))) {
            appRouters = appRouters.filter((r) => !r.startsWith(subItem.router));
          } else {
          }
        });
      } else {
        if (
          !(
            (nav.hasOwnProperty('skipPermission') && nav.skipPermission === 'true') ||
            (nav.conjunction === 'OR' &&
              nav.permissions.some((permission) =>
                currentUserPermissions.roles.includes(permission),
              )) ||
            (nav.conjunction === 'AND' &&
              nav.permissions.every((permission) =>
                currentUserPermissions.roles.includes(permission),
              ))
          )
        ) {
          appRouters = appRouters.filter((r) => !r.startsWith(nav.router));
        }
      }
    });

    if (appRouters.length > 0) {
      authorizedMfe.push({
        name: appName,
        host: getHost(appComp, cdn),
        routers: [...appRouters],
      });
    }

    allMfe.push({
      name: appName,
      host: getHost(appComp, cdn),
      routers: [...appComp.config.routers],
    });
  });

  apps.forEach((a) => {
    authorizedMfe.push({
      name: a.config.app,
      host: getHost(a, cdn),
      routers: [...a.config.routers],
    });
    allMfe.push({
      name: a.config.app,
      host: getHost(a, cdn),
      routers: [...a.config.routers],
    });
  });

  setAllMicroFrontends(allMfe);
  setAuthorizedMicroFrontends(authorizedMfe);
};

const setDefaultSettings = (data) => {
  return {
    ...data,
    timeZone: data.timeZone || defaultTimezoneKey,
    themeId: data.themeId || getDefaultThemeId(),
  };
};

export const onFetchSettingsSuccess = ({
  results,
  rawSettings,
  state,
  setState,
  user,
  setAuthorizedMicroFrontends,
  setAllMicroFrontends,
  setDsxUserData,
}) => {
  let basicSetup = getDataFromResults({ results, index: 0 });
  basicSetup.timezoneRegionId = basicSetup.timeZone || defaultTimezoneRegionId;
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: {...basicSetup},
    userProfile: setDefaultSettings(getDataFromResults({ results, index: 1 })),
    companyProfile: getDataFromResults({ results, index: 2 }),
  };
  const settings = mergeSettings(rawSettings.current);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const isProdCluster = getDataFromResults({ results, index: 4 });
  const currentUser = setDefaultSettings(getDataFromResults({ results, index: 1 }));
  const currentUserPermissions = getDataFromResults({ results, index: 2 });
  const currentUserWithZoneId = { ...currentUser, zoneId: `${user.current?.zid}` };
  setDsxUserData(currentUserWithZoneId);
  basicSetup.productFormat = '';
  addConfig('BasicSetup', basicSetup);
  addConfig('IsProdCluster', isProdCluster);
  addConfig(
    'CurrentUserPermissions',
    (currentUserPermissions.roles || []).map((p) => p.toUpperCase()),
  );
  addConfig('CurrentUser', currentUserWithZoneId);
  addConfig('ThemeSetting', currentUser?.themeId);
  updateFeatureToggle(getDataFromResults({ results, index: 3 }).resultList);
  themeId = currentUser?.themeId || getDefaultThemeId();

  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }

  user.current.databaseUserId = rawSettings.current.basicSetup.id;
  user.current.avatarIcon = { ...rawSettings.current.userProfile.avatarIcon };

  const appComponents = getConfig('appConfig').components;
  const { cdn } = getConfig('appConfig').config;
  addMfesByRoles(
    cdn,
    appComponents,
    currentUserPermissions,
    setAuthorizedMicroFrontends,
    setAllMicroFrontends,
  );

  setState((prevState) => {
    return {
      ...prevState,
      settings,
      user: rawSettings.current.userProfile,
    };
  });
};

export const onFetchAuthFailed = ({ error, state, setState }) => {
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=prp';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState((prevState) => {
      return {
        ...prevState,
        authUserError: typeof error === 'object' ? error?.message : error,
      };
    });
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState((prevState) => {
      return {
        ...prevState,
        initializing: false,
      };
    });
  }
};

export const renderBISInitiationMessage = ({ showBISCreationError, setShowBISCreationError }) => {
  return (
    <MessageBox
      open={showBISCreationError}
      onClose={() => setShowBISCreationError(false)}
      type="Information"
      style={{ maxWidth: '25rem' }}
    >
      {`You cannot raise a ticket using the integrated built-in support of SAP Predictive Replenishment because your subaccount ID could not be determined. Contact SAP Support for more details.`}
    </MessageBox>
  );
};

export const getUnAuthorizedMfes = ({ allMicroFrontends, authorizedMicroFrontends }) => {
  const unAuthorizedMicroFrontends = [];
  allMicroFrontends?.forEach((mfe) => {
    const unAuthorizedApp = authorizedMicroFrontends.find((each) => each.name === mfe.name);
    if (unAuthorizedApp === undefined) {
      unAuthorizedMicroFrontends.push(mfe);
    } else {
      const unAuthorizedSubRoutes = {
        ...unAuthorizedApp,
        routers: mfe.routers.filter((route) => !unAuthorizedApp.routers.includes(route)),
      };
      if (unAuthorizedSubRoutes.routers.length > 0) {
        unAuthorizedMicroFrontends.push(unAuthorizedSubRoutes);
      }
    }
  });
  const transformedUnAuthorizedRoutes = [];
  unAuthorizedMicroFrontends.forEach((eachApp) => {
    eachApp.routers.forEach((eachRoute) => {
      transformedUnAuthorizedRoutes.push({
        path: eachRoute,
        name: eachApp.name,
        component: PageNotAuthorized,
      });
    });
  });
  return transformedUnAuthorizedRoutes;
};

export const renderMfes = ({
  state,
  user,
  authorizedMicroFrontends,
  allMicroFrontends,
  showBISCreationError,
  setShowBISCreationError,
  feedbackDialogRef,
}) => {
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: authorizedMicroFrontends,
    history,
    settings: { ...state.settings.__raw },
    user,
  });
  const transformedUnAuthorizedRoutes = getUnAuthorizedMfes({
    allMicroFrontends,
    authorizedMicroFrontends,
  });
  const unAuthorizedRoutes = renderRouteConfigV3(
    transformedUnAuthorizedRoutes,
    '/',
    config,
    state.settings,
    user,
  );
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <Switch multiple={5}>
            {microFrontendRoutes}
            {unAuthorizedRoutes}
            {containerRoutes}
          </Switch>
        </Router>
        {state.timedOut && renderTimeoutMsg()}
        {renderFeedbackDialog({ feedbackDialogRef })}
        {renderBISInitiationMessage({ showBISCreationError, setShowBISCreationError })}
      </Provider>
    </ThemeProvider>
  );
};

const createBuiltInSupportPanel = ({ dsxUserData }) => {
  const s = document.createElement('script');
  s.setAttribute('id', 'builtInSupportPanel');
  s.src = sourceUrl;
  s.onload = function () {
    window['sdk-react-dsx-everywhere'].create({
      version: 'V1',
      baseUrl: baseUrl,
      useIcon: false,
      resizable: true,
      topOffset: '3rem',
      getUsersTheme: () => {
        return new Promise((resolve) => {
          resolve({
            theme: dsxUserData?.themeId ? dsxUserData.themeId : FALLBACK_THEME,
          });
        });
      },
      getProductContext: () => {
        return new Promise((resolve) => {
          resolve({
            productVersion: 'SAP Predictive Replenishment',
            PPMSIDs: ['73554900100900000000'],
            systemRole: 'PRP',
            hcpSubAccountID: dsxUserData?.zoneId ? `${dsxUserData.zoneId}` : null,
            language: dsxUserData?.language ? dsxUserData.language : FALLBACK_LANG,
          });
        });
      },
      getApplicationContext: () => {
        return new Promise((resolve) => {
          resolve({
            achComponent: 'IS-PRP',
            applicationName: 'SAP Predictive Replenishment',
          });
        });
      },
    });
  };
  document.body.appendChild(s);
};

const App = ({
  fetchConfig,
  fetchAuth,
  fetchCsrf,
  fetchSettings,
  fetchUserCreate,
  fetchUserDetails,
}) => {
  const [state, setState] = useState({
    initializing: true,
    fetchConfigError: false,
    fetchSettingsError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
    timedOut: false,
    triggerTimeoutCheck: false,
  });
  const feedbackDialogRef = useRef(null);

  const [authorizedMicroFrontends, setAuthorizedMicroFrontends] = useState([]);
  const [allMicroFrontends, setAllMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  const [dsxUserData, setDsxUserData] = useState();

  const surveyInvitationDialogCallback = async function (eventData) {
    feedbackDialogRef?.current?.show();
    const surveyInvitationDialogResponse = new Promise(
      function (resolve) {
        resolveSurveyInvitation = resolve;
      }.bind(this),
    );
    return surveyInvitationDialogResponse;
  };

  useEffect(() => {
    listenToEventBus({ rawSettings, state, setState, history });
    addConfig('application', 'prp');
    fetchConfig().then(
      (result) => {
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          state,
          setState,
          setAuthorizedMicroFrontends,
        });
        fetchAuth().then(
          (auth) => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                (result) => setCsrfToken(result?.data?.token),
                (error) => onFetchCsrfFailed({ error, user, state, setState }),
              )
              .finally(() => {
                fetchUserCreate().then(
                  (result) => {
                    fetchSettings(result?.data?.id)
                      .then(
                        (results) => {
                          onFetchSettingsSuccess({
                            results,
                            state,
                            setState,
                            rawSettings,
                            user,
                            setAuthorizedMicroFrontends,
                            setAllMicroFrontends,
                            setDsxUserData,
                          });
                        },
                        () =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
                              user: user.current,
                              fetchConfigError: false,
                            };
                          }),
                      )
                      .finally(() => {
                        onFetchSettingsFinally({
                          rawSettings,
                          state,
                          setState,
                          surveyInvitationDialogCallback,
                        });
                      });
                  },
                  () =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
                        user: user.current,
                        fetchConfigError: false,
                      };
                    }),
                );
              });
          },
          (error) => onFetchAuthFailed({ error, state, setState }),
        );
      },
      (error) => {
        console.error('Error:', error);
        setState((prevState) => {
          return {
            ...prevState,
            initializing: false,
            fetchConfigError: error,
          };
        });
      },
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.timedOut && state.triggerTimeoutCheck) {
      checkSessionTimeout({ fetchUserDetails, setState });
    }
  }, [state.timedOut, state.triggerTimeoutCheck]);

  const [showBISCreationError, setShowBISCreationError] = useState(false);

  useEffect(() => {
    if (dsxUserData) {
      createBuiltInSupportPanel({
        dsxUserData: dsxUserData,
      });
      if (!dsxUserData.zoneId) {
        setShowBISCreationError(true);
      }
    }
  }, [dsxUserData]);

  const theme = getThemeId();

  useEffect(() => {
    if (_pxApi !== null) {
      _pxApi.prevThemeId = _pxApi.currentThemeId;
      _pxApi.currentThemeId = theme;
      const prevPxAPIObject = getConfig(PX_API_OBJECT);
      const pxAPI = { ...prevPxAPIObject, pxAPIInstance: _pxApi };
      addConfig(PX_API_OBJECT, pxAPI);
    }
    window['sdk-react-dsx-everywhere']?.refreshContext(() => {
      return { theme: theme ? theme : FALLBACK_THEME };
    });
  }, [theme]);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.fetchSettingsError) {
    return renderError(
      'Failed to get company or user settings, please refresh page.'
    );
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({
    state,
    user,
    authorizedMicroFrontends,
    allMicroFrontends,
    showBISCreationError,
    setShowBISCreationError,
    feedbackDialogRef,
  });
};

export default App;
