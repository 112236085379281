import { cdnURL } from '../features/constants/app.constants';

export default {
  amountDecimalPlace: 2,
  priceDecimalPlace: 1,
  quantityDecimalPlace: 1,
  pencentageDecimalPlace: 1,
  rateDecimalPlace: 2,
  decimalSymbol: ',',
  thousandsSeparator: '.',
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'TWENTYFOUR_HOUR',
  language: 'en-CA',
  companyId: 411,
  companyName: 'colgate-dev1122w',
  companyLogoId: null,
  locationCountry: 'United States 2',
  locationState: '2California1',
  locationCity: '2LA',
  locationStreetAddress: '1288888',
  locationPostalCode: '12345',
  timeZone: 'GMT1000',
  email: 'sz.cn@sap.com',
  phone: '221',
  website: 'http://sap.com.shshhshshs.123444442',
  creationTime: '2020-10-12T13:53:02.350015',
  id: 418,
  userName: { firstName: 'sz-test2-120731', lastName: 'shengzhene' },
  userUpdateState: 299,
  status: 'INITIAL',
  tenantId: 5279,
  avatarIcon: { type: 'Initials', value: 'SS' },
};

export const components = [
    {
    name: 'sdmtest2-ui',
    location: `${cdnURL}/eureka/sdmtest2-ui/363fd53`,
    config: {
      app: 'sdmtest2',
      entrypoints: [
        {
          title: 'Application Sample',
          text: 'Sample page',
          icon: 'sap-icon--approvals',
          router: '/glory/sample/page',
        },
        {
          title: 'URL Sample',
          text: 'Google search',
          icon: 'sap-icon--search',
          url: 'https://www.google.com',
        },
      ],
      fragments: [
        {
          name: 'sdmtest2ReactFragmentSample1',
          section: ['Integration', 'Claims'],
        },
        {
          name: 'sdmtest2ReactFragmentSample2',
          section: ['Integration'],
        },
      ],
      routers: [
        '/businesspartner/list',
        '/businesspartner/edit/:id',
        '/businesspartner/view/:id',
        '/sdmtest2/product/list',
        '/sdmtest2/product/view/:id',
        '/sdmtest2/product/edit/:id',
        '/sdmtest2/product/create',
      ],
    },
  },
  {
    name: 'configuration-ui',
    location: `${cdnURL}/cic/configuration-ui/fd69ed5`,
    config: {
      app: 'configuration',
      entrypoints: [
        {
          title: 'Configuration_CompanySettingsTitle',
          text: 'Configuration_ViewCompanySettings',
          icon: 'sap-icon--product',
          router: '/configuration/setup',
        },
      ],
      routers: [
        '/configuration/profile/:id',
        '/configuration/setup',
        '/configuration/users-mgmt',
        '/configuration/integration',
        '/configuration/business-rules',
        '/configuration/settlement',
        '/configuration/communication',
        '/configuration/api-mgmt',
      ],
      sidenav: [
        {
          id: 'settings',
          text: 'Settings',
          icon: 'settings',
          router: '',
          items: [
            {
              id: 'setup',
              text: 'SideNav_Settings_SetUp',
              router: '/configuration/setup',
              order: 71,
              permissions: ['BasicSetup.READ', 'CompanyProfile.READ'],
            },
            {
              id: 'integration',
              text: 'SideNav_Settings_Integration',
              router: '/configuration/integration',
              order: 72,
              permissions: ['ReasonCodeConfiguration.READ'],
            },
            {
              id: 'user-roles',
              text: 'SideNav_Settings_UsersAndRoles',
              router: '/configuration/users-mgmt',
              order: 73,
              permissions: ['UsersandRolePermission.READ', 'Responsibility.READ'],
              conjunction: 'OR',
            },
            {
              id: 'settlement',
              text: 'SideNav_Settings_Settlement',
              router: '/configuration/settlement',
              order: 75,
              permissions: ['RunConfig.READ'],
            },
            {
              id: 'api-mgmt',
              text: 'SideNav_Settings_ApiMgmt',
              router: '/configuration/api-mgmt',
              order: 76,
              permissions: ['UsersandRolePermission.READ'],
            },
          ],
          order: 70,
        },
      ],
    },
  },
  {
    name: 'login-ui',
    location: `${cdnURL}/eureka/login-ui/a1ee218`,
    config: {
      app: 'login',
      routers: ['/login', '/access-denied', '/error/404'],
    },
  },
];

export const setting1 = {
  data: {
    currencyPosition: "After",
    dateFormat: "DD.MM.YYYY",
    decimalSymbol: ".",
    language: "en-US",
    outBoundConfigurations: [],
    percentageDecimalPlace: 3,
    priceDecimalPlace: 2,
    productFormat: "",
    productLength: "40",
    quantityDecimalPlace: 2,
    thousandsSeparator: ",",
    timeFormat: "TWELVE_HOUR",
    timeZone: null
  },
};
export const setting2 = {
  data: {
    id: 418,
    userName: { firstName: 'sz-test2-120731', lastName: 'shengzhene' },
    pictureId: null,
    email: 'sz.cn@sap.com',
    phone: '221',
    language: 'en',
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'TWENTYFOUR_HOUR',
    timeZone: 'GMT1000',
    creationTime: '2020-10-12T13:53:02.350015',
    userUpdateState: 297,
    status: 'INITIAL',
    tenantId: 5279,
    role: null,
    externalUserId: null,
    avatarIcon: { type: 'Initials', value: 'SS' },
    familyNameFirst: false,
    s4ExternalUserId: null
  },
};
export const setting3 = {
  data: {
    roles:["ManageOrderProposal.READ","TransferSchedule.READ","UomCategory.READ"]
  },
};
export const setting5 = {
  data: false
};
export const setting4 = {
  data: {
    resultList: [
      { feature: 'feature-bbbbb', isEnable: false },
      { feature: 'agreement-search-getAgreements', isEnable: false },
      { feature: 'validated-amount-maple-leaf-ITCM-4511', isEnable: false },
      { feature: 'settlement-test1', isEnable: false },
      { feature: 'my-fancy-feature', isEnable: false },
      { feature: 'python-frw-test', isEnable: true },
      { feature: 'feature-aaa', isEnable: false },
    ],
  },
};
